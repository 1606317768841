@font-face {
  font-family: "adobe-caslon-w01-smbd";
  src: url("./assets/fonts/adobe-caslon-w01-smbd.woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "adobe-caslon-w01-smbd", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-header {
  color: #8b0000;
  font-size: 48px;
  font-family: "adobe-caslon-w01-smbd", sans-serif;
  letter-spacing: normal;
}

.custom-title {
  font-style: italic;
  color: #8b0000;
  font-size: 32px;
  font-family: "adobe-caslon-w01-smbd", sans-serif;
  letter-spacing: normal;
  text-align: center;
}

.custom-title-1 {
  color: #8b0000;
  font-size: 24px;
  font-family: "adobe-caslon-w01-smbd", sans-serif;
  letter-spacing: normal;
  text-align: center;
}

.custom-title-2 {
  color: #a4864f;
  font-size: 20px;
  font-family: "adobe-caslon-w01-smbd", sans-serif;
  letter-spacing: normal;
}

@font-face {
  font-family: "Avenir LT W01 Light";
  src: url("./assets/fonts/avenir-lt-w01_35-light1475496.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.ant-typography {
  font-family: "Avenir LT W01 Light", sans-serif;
  text-align: justify;
  color: #000;
  font-size: 18px;
  letter-spacing: normal;
  padding: 16px;
}

.ff-adobe {
  font-family: "adobe-caslon-w01-smbd", sans-serif;
}

.ff-avenir {
  font-family: "Avenir LT W01 Light", sans-serif;
}
