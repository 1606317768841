.header {
  height: 80px;
  width: 100%;
  position: fixed;
  background-color: #8b0000;
  z-index: 1000;
}

.container {
  max-width: 1200px;
  overflow: hidden;
  margin: auto;
  height: 100%;
  padding: 0px 16px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.nav__logo {
  color: #fff;
  transition: color 0.4s;
  font-size: 24px;
  text-decoration: none;
  text-transform: uppercase;
}

.nav__cta {
  background-color: var(--first-color);
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.75rem 1.5rem;
}

.nav__list {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.nav__item {
  padding-left: 24px;
}

.nav__link {
  color: #fff;
  font-weight: 600;
  transition: color 0.4s;
  text-decoration: none;
}

.nav__link:hover {
  color: #a4864f;
}

.ant-dropdown-menu-item .nav__link.active {
  color: #a4864f !important;
  font-weight: bold;
}

.active {
  color: #a4864f;
  font-weight: bold;
  text-decoration: underline;
}

.show-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
}

.nav__listt {
  display: flex;
  flex-direction: row;
  column-gap: 2.5rem;
}

.nav__list_mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav__list {
    display: none;
  }

  .nav__list_mobile {
    display: block;
  }
}